
<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div
          slot="header"
          style="position:relative"
        >
          <div class="query spaceBetween">
            <div>
              <el-input
                clearable
                placeholder="请输入字典key"
                v-model="queryData.like[0][2]"
                @keyup.enter.native="getDataList(true)"
              ></el-input>

              <el-button
                type="success"
                class="ml10"
                @click="getDataList(true)"
              >查询</el-button>
            </div>
            <div>
              <el-button
                type="primary"
                @click="addOrEditPopup({type:1})"
              >新增字典（弹窗）</el-button>
              <el-button
                type="primary"
                @click="addOrEditPopup2()"
              >新增字典（新界面）</el-button>
            </div>
          </div>
        </div>

        <el-table
          :data="listData"
          v-loading="loading"
          element-loading-text="正在拼命加载中"
          element-loading-spinner="el-icon-loading"
          border
          stripe
        >
          <el-table-column
            type="index"
            :index="1"
            label="#"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="字典名称"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="paramKey"
            label="字典key"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="字典value类型"
            min-width="160"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type==1">文本</el-tag>
              <el-tag v-if="scope.row.type==2">富文本</el-tag>
              <el-tag v-if="scope.row.type==3">JSON</el-tag>
              <el-tag v-if="scope.row.type==4">分享</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            min-width="160"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="operation"
            label="操作"
            width="160px;"
          >
            <template slot-scope="scope">
              <div class="tableOperation">
                <el-button
                  type="text"
                  @click="addOrEditPopup(scope.row)"
                >编辑</el-button>
                <span
                  class="operationLine"
                >|</span>
                <el-button
                  class="F56C6C"
                  type="text"
                  @click="del(scope.row.id)"
                >删除</el-button>
              </div>
            </template>
          </el-table-column>

        </el-table>
        <!--分页-->
        <el-pagination
          class="floatRight"
          v-if="total > 10"
          @size-change="getDataListSizeChange"
          @current-change="getDataListCurrentChange"
          :current-page.sync="queryData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryData.size"
          :total="total"
          layout="total,sizes, prev, pager, next"
        ></el-pagination>
      </el-card>
    </div>
    <el-dialog
      :title="(addOrEditForm.id?'修改':'新增')+'数据字典'"
      :visible.sync="addOrEditVisible"
      width="700px"
      center
    >
      <el-form
        :model="addOrEditForm"
        ref="addOrEditForm"
        :rules="addOrEditRule"
        label-width="100px"
      >
        <el-form-item
          label="字典名称"
          prop="name"
        >
          <el-input
            v-model="addOrEditForm.name"
            placeholder="请输入字典名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="字典key"
          prop="paramKey"
        >
          <el-input
            v-model="addOrEditForm.paramKey"
            placeholder="请输入字典key"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="字典value"
          prop="paramValue"
          v-if="addOrEditForm.type==1"
        >
          <el-input
            v-model="addOrEditForm.paramValue"
            placeholder="请输入字典value"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="value类型"
          prop="type"
        >
          <el-radio-group v-model="addOrEditForm.type">
            <el-radio :label="1">文本</el-radio>
            <el-radio :label="2">富文本</el-radio>
            <el-radio :label="3">JSON</el-radio>
            <el-radio :label="4">分享</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="分享标题"
          v-if="addOrEditForm.type==4"
        >
          <el-input
            v-model="shareInfo.title"
            placeholder="请输入分享标题"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="分享描述"
          v-if="addOrEditForm.type==4"
        >
          <el-input
            v-model="shareInfo.desc"
            placeholder="请输入分享描述"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="分享链接"
          v-if="addOrEditForm.type==4"
        >
          <el-input
            v-model="shareInfo.link"
            placeholder="请输入分享链接"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="分享图片"
          v-if="addOrEditForm.type==4"
        >

          <el-upload
            class="avatar-uploader width50height50"
            :action="$store.state.uploadImgUrl"
            :show-file-list="false"
            :on-success="uploadSuccessImgUrl"
            :on-error="this.$uploadError"
            :before-upload="this.$beforeUploadImage"
            accept="image/*"
          >
            <img
              style="display: block;max-width:100%;"
              v-if="shareInfo.imgUrl"
              :src="shareInfo.imgUrl"
            >
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            v-model="addOrEditForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="JSON"
          v-if="addOrEditForm.type==3"
        >
          <div
            v-for="(item,index) in addOrEditFormJson"
            class="addOrEditFormJson"
            @click.stop="setUploadImgIndex(index)"
          >
            <el-upload
              class="avatar-uploader width50height50"
              :action="$store.state.uploadImgUrl"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :on-error="this.$uploadError"
              :before-upload="this.$beforeUploadImage"
              accept="image/*"
            >
              <img
                style="display: block;max-width:100%;"
                v-if="item.icon"
                :src="item.icon"
              >
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
            <el-input
              style="width:400px;"
              :rows="3"
              v-model="item.describe"
              type="textarea"
            ></el-input>
          </div>
          <el-button
            type="text"
            @click="addOrEditFormJson.push({})"
          >增加一项</el-button>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addOrEditVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addOrEdit()"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
        like: [["String", "param_key", ""]],
        orderBy: [["Number ", "create_time", "false"]],
      },
      addOrEditVisible: false,
      addOrEditForm: {},
      addOrEditFormJson: [],
      addOrEditRule: {
        name: [{ required: true, message: "该项为必填项", trigger: "blur" }],
        paramKey: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
        paramValue: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
      },
      imgIndex: "",
      shareInfo: { title: "", desc: "", link: "", imgUrl: "" },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    setUploadImgIndex(index) {
      this.imgIndex = index;
    },
    /**
     * 上传图片成功
     */
    uploadSuccess(res) {
      this.addOrEditFormJson[this.imgIndex].icon = res.data;
      this.$forceUpdate();
      this.$store.state.beforeUploadLoading.close();
    },
    uploadSuccessImgUrl(res) {
      this.$store.state.beforeUploadLoading.close();
      this.$set(this.shareInfo, "imgUrl", res.data);
    },
    uploadError(err) {
      this.$message({
        message: "操作失败，请重试或联系管理员",
        type: "warning",
      });
    },
    addOrEditPopup2(id) {
      this.$router.push({
        name: "dictionariesAddOrEdit",
        query: {
          id: id,
        },
      });
    },
    /**
     * 新增或修改弹窗
     */
    addOrEditPopup(row) {
      if (row.type == 2) this.addOrEditPopup2(row.id);
      else {
        if (row) {
          this.addOrEditForm = JSON.parse(JSON.stringify(row));
          if (row.type == 3)
            this.addOrEditFormJson = JSON.parse(row.paramValue);
          if (row.type == 4) this.shareInfo = JSON.parse(row.paramValue);
        } else this.addOrEditForm = { type: 1 };
        this.addOrEditVisible = true;
      }
    },
    addOrEdit() {
      this.$refs["addOrEditForm"].validate((valid) => {
        if (valid) {
          let data = this.addOrEditForm;
          if (data.type == 3)
            data.paramValue = JSON.stringify(this.addOrEditFormJson);
          if (data.type == 4) data.paramValue = JSON.stringify(this.shareInfo);
          this.$http
            .post(
              `admin/sys-dictionary/saveOrUpdateDic/${this.getUserId()}`,
              this.addOrEditForm
            )
            .then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.addOrEditVisible = false;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        }
      });
    },
    del(id) {
      if (id) {
        this.$confirm("是否删除所选择的数据?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http
              .delete(`admin/sys-dictionary/baseDeleteById/${id}`)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      this.$http
        .post(`admin/sys-dictionary/page`, this.$guolv(this.queryData))
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            } else {
              this.listData = [];
              this.total = 0;
            }
            this.loading = false;
          }
        });
    },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>
<style lang="less" scoped>
.addOrEditFormJson {
  display: flex;
  align-items: center;
  .icon {
    width: 100px;
    margin-right: 20px;
  }
}
</style>
